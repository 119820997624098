import { AxiosRequestConfig } from "axios";

const protocol = window.location.protocol;
const hostname = window.location.hostname;

export const requestConfig: AxiosRequestConfig = {
  baseURL:
    process.env.NODE_ENV === `development`
      ? process.env.VUE_APP_BASE_URL
      : protocol.concat(`//`).concat(hostname),
  // 设置axios请求头
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
  timeout: 120000,
  // withCredentials: true,
};
