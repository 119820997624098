import { HttpStatus, RequestMethod } from "@/common/enums";
import { axios } from "@/util";
import _ from "lodash";

/**
 * 发起HTTP请求
 *
 * @template I 请求类型
 * @template O 响应类型
 * @param method 请求方法
 * @param url 请求地址
 * @param payload 有效载荷
 */
const request = async <I, O>(
  method: RequestMethod,
  url: string,
  payload: I
): Promise<O> => {
  switch (method) {
    case RequestMethod.GET:
      return (
        await axios.get(url, {
          params: payload,
          validateStatus: (status) => _.isEqual(status, HttpStatus.OK),
        })
      ).data;
    case RequestMethod.POST:
      return (
        await axios.post(url, payload, {
          validateStatus: (status) => _.isEqual(status, HttpStatus.CREATED),
        })
      ).data;
    case RequestMethod.PUT:
      return (
        await axios.put(url, payload, {
          validateStatus: (status) => _.isEqual(status, HttpStatus.NO_CONTENT),
        })
      ).data;
    case RequestMethod.DELETE:
      return (
        await axios.delete(url, {
          params: payload,
          validateStatus: (status) => _.isEqual(status, HttpStatus.NO_CONTENT),
        })
      ).data;
    default:
      // 请求方法错误
      return Promise.reject(ErrorResponse.E9999);
  }
};

/**
 * GET请求
 *
 * @template I 请求类型
 * @template O 响应类型
 * @param url 请求地址
 * @param params 请求参数
 */
// todo 响应类型在前 请求类型在后
const get = <I, O>(url: string, params?: I): Promise<O> => {
  return request(RequestMethod.GET, url, params);
};

/**
 * POST请求
 *
 * @template I 请求类型
 * @param url 请求地址
 * @param data 请求体
 */
const post = async <I>(url: string, data: I): Promise<void> => {
  request(RequestMethod.POST, url, data);
};

/**
 * PUT请求
 *
 * @template I 请求类型
 * @param url 请求地址
 * @param data 请求体
 */
const put = async <I>(url: string, data: I): Promise<void> => {
  request(RequestMethod.PUT, url, data);
};

/**
 * DELETE请求
 *
 * @template I 请求类型
 * @param url 请求地址
 * @param params 请求参数
 */
const del = async <I>(url: string, params: I): Promise<void> => {
  request(RequestMethod.DELETE, url, params);
};

export const commonRequest = {
  get,
  post,
  put,
  del,
};
