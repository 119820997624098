import { MutationTree } from "vuex";

export const enum CSMutationType {
  // 更新歌曲
  UPDATE_SONG = "UPDATE_SONG",
  // 播放
  PLAY = "PLAY",
  // 暂停
  PAUSE = "PAUSE",
  // 更新歌曲当前时间
  UPDATE_CURRENT_TIME = "UPDATE_CURRENT_TIME",
  // 更新歌曲总时长
  UPDATE_DURATION = "UPDATE_DURATION",
  // todo 更新歌曲缓冲时间
  // 更新进度条(用户手动触发)
  UPDATE_SONG_MANUAL = "UPDATE_SONG_MANUAL",
}

export const currentSongStateMutations: MutationTree<CurrentSongState> = {
  // 更新歌曲
  [CSMutationType.UPDATE_SONG](state, song: Song) {
    state.songName = song.songName;
    // todo 字符串常量化
    state.src = `${process.env.VUE_APP_SONG_SRC}${song.path}`;
    state.performanceDate = song.performanceDate;
    state.currentTime = 0.0;
  },

  // 播放
  [CSMutationType.PLAY](state) {
    state.isPlaying = true;
  },

  // 暂停
  [CSMutationType.PAUSE](state) {
    state.isPlaying = false;
  },

  // 更新歌曲当前时间
  [CSMutationType.UPDATE_CURRENT_TIME](state, currentTime: number) {
    state.currentTime = currentTime;
  },

  // 更新歌曲总时长
  [CSMutationType.UPDATE_DURATION](state, duration: number) {
    state.duration = duration;
  },

  // 更新进度条(用户手动触发)
  [CSMutationType.UPDATE_SONG_MANUAL](state, newPosition: number) {
    state.currentTime = newPosition * state.duration;
  },
};
