import { store } from "@/store";
import Vant from "vant";
import "vant/lib/index.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);
// 初始化不显示Loading遮罩
app.config.globalProperties.$showLoading = false;
app.use(router).use(store).use(Vant).mount("#app");
