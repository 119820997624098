import { GetterTree } from "vuex";

export const enum GCGetterType {
  // 请求计数器
  requestCounter = "requestCounter",
  // 前后端是否交互中
  isInteracting = "isInteracting",
  // 获取查询条件
  queryContent = "queryContent",
  // 展示页面
  tabName = "tabName",
  // 获取歌曲集合列表
  songCollectionResourceList = "songCollectionResourceList",
  // 获取歌曲列表
  songResourceList = "songResourceList",
  // 获取当前页码
  page = "page",
  // 获取总页码
  totalPage = "totalPage",
  // 获取每页查询结果数目
  pageSize = "pageSize",
}

export const globalControlStageGetters: GetterTree<
  GlobalControlState,
  RootState
> = {
  [GCGetterType.requestCounter]: (state) => state.requestCounter,

  [GCGetterType.isInteracting]: (state) => state.requestCounter > 0,

  [GCGetterType.queryContent]: (state) => state.queryContent,

  [GCGetterType.tabName]: (state) => state.activeTab,

  [GCGetterType.songCollectionResourceList]: (state) =>
    state.songCollectionResourceList,

  [GCGetterType.songResourceList]: (state) => state.songResourceList,

  [GCGetterType.page]: (state) => state.queryContent.queryPage,

  [GCGetterType.totalPage]: (state) => state.totalPage,

  [GCGetterType.pageSize]: (state) => state.pageSize,
};
