
import { queryAll } from "@/api/query.api";
import { ModuleName } from "@/store";
import { CSGetterType } from "@/store/current-song/getters";
import { CSMutationType } from "@/store/current-song/mutations";
import { PLGetterType } from "@/store/play-list/getters";
import { PLMutationType } from "@/store/play-list/mutations";
import { PlayListItem, QueryAllResponse } from "@/types";
import { sessionUtil, timeUtil } from "@/util";
import { defineComponent, reactive } from "@vue/runtime-core";
import _ from "lodash";
import { toRefs } from "vue";
import { mapGetters, mapMutations, useStore } from "vuex";
import PlayList from "./PlayList.vue";

export default defineComponent({
  name: "MusicPlayer",

  components: {
    PlayList,
  },

  setup() {
    const store = useStore();
    const data = reactive({});

    return {
      store,
      ...toRefs(data),
    };
  },

  data() {
    if (_.isNil(sessionUtil.getVolume())) {
      sessionUtil.setVolume();
    }

    return {
      // 是否展示歌单
      showPlayList: _.stubFalse(),
      // 是否展示歌曲详细
      showDetail: _.stubFalse(),
      // 歌曲标题
      songTitle: _.stubString(),
      // 翻唱歌手
      coverSingerNames: _.stubString(),
      // 创建者
      creator: _.stubString(),
      // 标签值
      tags: _.stubObject(),
      // 音量
      volume: sessionUtil.getVolume(),
    };
  },

  mounted() {
    this.onChangeVolume(this.volume);
  },

  computed: {
    ...mapGetters(ModuleName.CS, {
      isPlaying: CSGetterType.isPlaying,
      isLoading: CSGetterType.isLoading,
      duration: CSGetterType.duration,
      currentTime: CSGetterType.currentTime,
      songName: CSGetterType.songName,
      performanceDate: CSGetterType.performanceDate,
      src: CSGetterType.src,
      position: CSGetterType.position,
    }),

    ...mapGetters(ModuleName.PL, {
      playList: PLGetterType.playList,
      playIndex: PLGetterType.playIndex,
      isLastSong: PLGetterType.isLastSong,
      isFirstSong: PLGetterType.isFirstSong,
    }),
  },

  methods: {
    ...mapMutations(ModuleName.CS, {
      updateDuration: CSMutationType.UPDATE_DURATION,
      updateSongManual: CSMutationType.UPDATE_SONG_MANUAL,
      play: CSMutationType.PLAY,
      pause: CSMutationType.PAUSE,
      setSong: CSMutationType.UPDATE_SONG,
      updateCurrentTime: CSMutationType.UPDATE_CURRENT_TIME,
    }),

    ...mapMutations(ModuleName.PL, {
      clearList: PLMutationType.CLEAR,
      addSong: PLMutationType.ADD_SONG,
      playNext: PLMutationType.PLAY_NEXT,
      playPrev: PLMutationType.PLAY_PREV,
      playSong: PLMutationType.PLAY_SONG,
    }),

    /**
     * 获取音量图标
     */
    getVolumeButton(): string {
      if (this.volume == 0) {
        return require("@/assets/image/volume-x.svg");
      } else if (this.volume <= 50) {
        return require("@/assets/image/volume-1.svg");
      } else {
        return require("@/assets/image/volume-2.svg");
      }
    },

    /**
     * 更改音量
     *
     * @param volume 音量大小
     */
    onChangeVolume(volume: number): void {
      this.getAudio().volume = volume / 100;
      sessionUtil.setVolume(volume);
    },

    /**
     * 格式化时间
     * ss.ffffff => mm:ss
     *
     * @param time 秒数
     */
    getTime(time: number): string {
      return timeUtil.parseTime(time);
    },

    /**
     * 获取歌曲元素
     */
    getAudio(): HTMLAudioElement {
      return this.$refs.audioRef as HTMLAudioElement;
    },

    /**
     * 滚动条位置更新
     */
    updatePosition(value: number): void {
      this.updateSongManual(value);
      this.getAudio().currentTime = this.currentTime;
    },

    /**
     * 切换播放状态
     */
    async togglePlaying(): Promise<void> {
      // 判断是否有已被初始化的歌曲
      if (_.isEmpty(this.src)) {
        await this.setRandomPlayList();
      }
      if (this.isPlaying) {
        this.pause();
        this.getAudio().pause();
      } else {
        this.play();
        this.getAudio().play();
      }
    },

    /**
     * 歌曲结束
     */
    async audioEnd(): Promise<void> {
      await this.playNextSong();
    },

    /**
     * 播放上一首
     */
    playPrevSong(): void {
      if (this.isFirstSong) {
        return;
      } else {
        this.playPrev();
        this.setPlayItem(this.playList[this.playIndex]);
        this.playItem();
      }
    },

    /**
     * 播放下一首
     */
    async playNextSong(): Promise<void> {
      if (this.playList.length === 0 || this.isLastSong) {
        await this.setRandomPlayList();
      } else {
        this.playNext();
      }
      this.setPlayItem(this.playList[this.playIndex]);
      this.playItem();
    },

    /**
     * 音频时间更新
     */
    updateTime(): void {
      this.updateCurrentTime(this.getAudio().currentTime);
    },

    /**
     * 设置随机歌单
     *
     * @param amount 随机歌单长度
     */
    async setRandomPlayList(amount = 40): Promise<void> {
      const datas = await this.getAllInfo();

      this.clearList();
      const maxSize = datas.songs.length;
      for (let i = 0; i < amount && i < maxSize; ++i) {
        const song = datas.songs[_.random(maxSize)];
        const songCollection = datas.songCollections.find((item) =>
          _.eq(item.songCollection.songCollectionId, song.song.songCollectionId)
        );

        // 添加到播放列表
        this.addSong({
          song: song.song,
          songCollection: songCollection.songCollection,
          tags: song.tagValues,
          originalSingers: songCollection.singers,
          coverSingers: song.singers,
        });
      }

      this.setPlayItem(this.playList[this.playIndex]);
    },

    /**
     * 设置指定条件歌单
     *
     * @param searchText 检索文本
     */
    async setSpecifiedPlayList(searchText: string): Promise<void> {
      if (_.isEmpty(searchText)) {
        return;
      }
      const datas = await this.getAllInfo();
      const fullPlayList: PlayListItem[] = datas.songs.map((song) => {
        const songCollection = datas.songCollections.find((item) =>
          _.eq(item.songCollection.songCollectionId, song.song.songCollectionId)
        );

        // 添加到播放列表
        return {
          song: song.song,
          songCollection: songCollection.songCollection,
          tags: song.tagValues,
          originalSingers: songCollection.singers,
          coverSingers: song.singers,
        };
      });

      this.clearList();
      _.sortBy(
        fullPlayList.filter(
          (playItem) =>
            // TODO 通过歌名查找
            _.includes(playItem.songCollection.songTitle, searchText)
          // TODO 通过歌手名查找
          // _.includes(_.map(playItem.originalSingers, "singerName"), "みきとP")
          // TODO 通过日期查找
          // _.eq(playItem.song.performanceDate, "2020-08-01") &&
          // TODO 通过标签查找
          // _.includes(playItem.tags, "吉他键盘")
        ),
        (playItem) =>
          // TODO 按日期升顺排序
          playItem.song.performanceDate
      ).forEach((playItem) => this.addSong(playItem));

      this.setPlayItem(this.playList[this.playIndex]);
    },

    /**
     * 设置歌曲
     *
     * @param playListItem 播放列表项目
     */
    setPlayItem(playListItem: PlayListItem): void {
      // 将有效数据绑定到组件上
      this.songTitle = playListItem.songCollection.songTitle;
      this.coverSingerNames = _.join(
        playListItem.originalSingers.map((singer) => singer.singerName),
        "／"
      );
      this.tags = playListItem.tags;
      this.creator = playListItem.song.creator;

      const audio = this.getAudio();
      audio.src = `${process.env.VUE_APP_SONG_SRC}${playListItem.song.path}`;
      audio.oncanplaythrough = () => this.updateDuration(audio.duration);
      // 设置当前播放歌曲
      this.setSong(playListItem.song);
    },

    /**
     * 获取所有歌曲信息
     */
    async getAllInfo(): Promise<QueryAllResponse> {
      // 既存数据有效性检查
      if (
        _.gt(new Date(), sessionUtil.getExpireTime()) ||
        _.isNil(sessionUtil.getInitData())
      ) {
        // 重新请求后端接口
        sessionUtil.setInitData(await queryAll());
        sessionUtil.setExpireTime();
      }
      return sessionUtil.getInitData();
    },

    /**
     * 播放歌曲
     */
    playItem(): void {
      // 暂停播放歌曲
      this.pause();
      // 播放歌曲
      _.delay(() => {
        this.getAudio().play();
        this.play();
      }, 300);
    },
  },
});
