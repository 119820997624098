
import { store } from "@/store";
import { defineComponent } from "@vue/runtime-core";
import { reactive, toRefs } from "vue";

export default defineComponent({
  name: "Description",

  emits: ["onClose"],

  setup() {
    const data = reactive({});

    return {
      store,
      ...toRefs(data),
    };
  },

  data() {
    return {
      // 展开标题
      activeName: 1,
    };
  },

  methods: {
    onClickButton() {
      this.$emit("onClose");
    },
  },
});
