import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Main from "../views/Main.vue";
import Manage from "../views/Manage.vue";
import Operation from "../views/Operation.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "Main",
    meta: {
      title: "牵丝蝠の疗养所",
    },
    component: Main,
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "首页",
    },
    component: Home,
  },
  {
    path: "/manage",
    name: "Manage",
    component: Manage,
  },
  {
    path: "/operate",
    name: "Operate",
    component: Operation,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = String(to.meta.title ? to.meta.title : "牵丝蝠の疗养所");
  next();
});

export default router;
