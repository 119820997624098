import { ModuleName, store } from "@/store";
import { GCMutationType } from "@/store/global-control/mutations";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";
import { logger } from "./LogUtil";
import { requestConfig } from "./config";

/**
 * 拦截器
 */
class Interceptor {
  private axiosInstance: AxiosInstance;

  constructor() {
    // 创建axios实例
    this.axiosInstance = axios.create(requestConfig);
    // 初始化拦截器
    this.initInterceptor();
  }

  /**
   * 获取axios单例对象
   */
  public getAxiosInstance(): AxiosInstance {
    return this.axiosInstance;
  }

  /**
   * 初始化拦截器
   */
  private initInterceptor(): void {
    // 添加请求拦截器
    this.axiosInstance.interceptors.request.use(
      (req: AxiosRequestConfig) => {
        store.commit(`${ModuleName.GC}/${GCMutationType.ADD_REQUEST}`);
        if (req.method === "get") {
          // get请求传递数组时做格式化
          req.paramsSerializer = (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          };
        }
        // 请求成功
        // logger.info("request success", req);
        return Promise.resolve(req);
      },
      (error) => {
        // 请求失败
        logger.error("request failure", error.request);
        logger.error("unknown error", error.message);
        return Promise.reject(ErrorResponse.E9999);
      }
    );

    // 添加响应拦截器
    this.axiosInstance.interceptors.response.use(
      (res: AxiosResponse) => {
        store.commit(`${ModuleName.GC}/${GCMutationType.END_REQUEST}`);
        // 响应成功
        // logger.info("response success", res);
        return Promise.resolve(res);
      },
      (error) => {
        store.commit(`${ModuleName.GC}/${GCMutationType.END_REQUEST}`);
        // 响应失败
        logger.error("response failure", error.response);
        const errorResponse = error.response.data;
        if (errorResponse) {
          logger.error("error detail", errorResponse);
          return Promise.reject(errorResponse);
        } else {
          logger.error("unknown error", error.message);
          return Promise.reject(ErrorResponse.E9999);
        }
      }
    );
  }
}

export const interceptor: Interceptor = new Interceptor();
