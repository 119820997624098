import { Module } from "vuex";

/**
 * 初期化历史记录状态
 */
const initHistoryRecordState: HistoryRecordState = {
  records: [],
};

export const hr: Module<HistoryRecordState, RootState> = {
  namespaced: true,

  state: initHistoryRecordState,

  getters: {},

  mutations: {},

  actions: {},
};
