import { Module } from "vuex";
import { currentSongStageGetters } from "./getters";
import { currentSongStateMutations } from "./mutations";

/**
 * 初期化当前播放歌曲状态
 */
const initCurrentSongState: CurrentSongState = {
  isPlaying: false,
  isLoading: false,
  duration: 0.0,
  currentTime: 0.0,
  position: 0.0,
  songName: "",
  performanceDate: "",
  src: "",
};

export const cs: Module<CurrentSongState, RootState> = {
  namespaced: true,

  state: initCurrentSongState,

  getters: currentSongStageGetters,

  mutations: currentSongStateMutations,

  actions: {},
};
