import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_loading = _resolveComponent("loading")!
  const _component_van_overlay = _resolveComponent("van-overlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_van_overlay, { show: _ctx.isInteracting }, {
      default: _withCtx(() => [
        _createVNode(_component_loading)
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}