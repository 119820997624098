<template>
  <div class="wrapper">
    <van-loading size="40px" vertical>加载中...</van-loading>
  </div>
</template>

<script lang="ts">
</script>

<style lang="scss">
@import "~@/assets/css/Loading.scss";
</style>