/**
 * 播放模式
 */
export const enum PlayMode {
  RANDOM, // 随机播放
  IN_ORDER, // 顺序播放(播放到歌单末尾后停止)
  LIST_LOOP, // 列表循环(播放到歌单末尾后从第一首开始顺序播放)
  SONG_LOOP, // 单曲循环
}

/**
 * 请求方法
 */
export const enum RequestMethod {
  GET,
  POST,
  PUT,
  DELETE,
}

/**
 * HTTP状态码
 */
export const enum HttpStatus {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
}

/**
 * 查询选项
 */
export const enum QueryOption {
  UNSPECIFIED, // 未指定
  SONG_TITLE, // 歌曲标题
  SINGER_NAME, // 歌手名称
  PERFORMANCE_DATE, // 弹唱日期
  TAG_ID, // 标签id
}
